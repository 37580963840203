//various site-wide methods, please ensure the various libraries are loaded before this file (e.g. select2, matchHeight, etc)

jQuery(document).ready(function($) {

	//--------------------------------------------------------
	// MATCH HEIGHT
	//--------------------------------------------------------

	if (jQuery.fn.matchHeight) {

		jQuery('.equal-height').matchHeight();

		var mhOptions = {
			byRow: false,
			property: 'height',
			remove: false
		}

		jQuery('#pricing-section div.description').matchHeight(mhOptions);
		jQuery('#pricing-section .pricing-section-equal-height').matchHeight(mhOptions);

	}

	//--------------------------------------------------------
	// SECONDARY NAV
	//--------------------------------------------------------

	//add buttons to the parent elements to trigger the nav
	jQuery('<i class="fa fa-chevron-down secondary-navigation-drop" aria-hidden="true"></i>').insertAfter('#secondary-navigation .parent');

	//add click listeners to these elements
	jQuery('.secondary-navigation-drop').click(function(e) {

		//show this menu
		if (jQuery(this).hasClass('fa-chevron-down')) {

			jQuery(this).prev().find('ul').show();
			jQuery(this).removeClass('fa-chevron-down').addClass('fa-chevron-up');

			//shut all other menus and reset the arrows apart from this
			jQuery('.secondary-navigation-drop').not(this).prev().find('ul').hide();
			jQuery('.secondary-navigation-drop').not(this).removeClass('fa-chevron-up').addClass('fa-chevron-down');

		}
		//else shut all menus including this
		else {

			jQuery('.secondary-navigation-drop').prev().find('ul').hide();
			jQuery('.secondary-navigation-drop').removeClass('fa-chevron-up').addClass('fa-chevron-down');

		}

		//stop the body event triggering
		e.stopPropagation();
		return false;

	});

	jQuery('#secondary-navigation .parent').mouseover(function() {

		jQuery(this).find('ul').show();

		jQuery(this).next().addClass('active');

		if (window.innerWidth < 1200) {

			jQuery(this).next().removeClass('fa-chevron-down').addClass('fa-chevron-up');

		}

	});

	jQuery('#secondary-navigation .parent').mouseleave(function() {

		jQuery(this).find('ul').hide();

		jQuery('.secondary-navigation-drop').removeClass('fa-chevron-up').addClass('fa-chevron-down');
		jQuery(this).next().removeClass('active');

	});

	//close all menu items if the body is clicked
	jQuery(document).click(function() {

		jQuery('#secondary-navigation .parent').find('ul').hide();
		jQuery('.secondary-navigation-drop').removeClass('fa-chevron-up').addClass('fa-chevron-down');

	});

	//burger
	jQuery('#secondary-nav-trigger').click(function() {

		jQuery('#secondary-navigation .menu').slideToggle();

	});

	//--------------------------------------------------------
	// SELECT 2
	//--------------------------------------------------------

	if (jQuery.fn.select2) {

		//recent list filter
		/* jQuery('#list-filter').select2({

			minimumResultsForSearch: -1, //hide the search box
			width: '340px'

		}); */

		//run select2 apart from in marketo
		jQuery('select').not(".mktoFieldWrap select").select2({

			minimumResultsForSearch: -1, //hide the search box

		});
	}

	jQuery(document).ready(function() {

		if (jQuery().tooltip) {

			jQuery('[data-toggle="tooltip"]').tooltip()

		}

	});
});

//--------------------------------------------------------
// GET QUERY PARAMS FROM URL
//--------------------------------------------------------

function gptwGetUrlParameter(sParam) {
	var sPageURL = window.location.search.substring(1),
		sURLVariables = sPageURL.split('&'),
		sParameterName,
		i;

	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split('=');

		if (sParameterName[0] === sParam) {
			return sParameterName && !sParameterName[1] ? true : decodeURIComponent(sParameterName[1]);
		}
	}
};

//--------------------------------------------------------
// STICKY ELEMENTS
//--------------------------------------------------------

jQuery(document).ready(function() {

	jQuery('.custom-sticky, .gptw-sticky').each(function() {

		var offset = jQuery('#hello-bar:visible').height() + jQuery('#gptw-navigation .desktop-bottom:visible').height() + jQuery('.mobile-header:visible').first().height();
		this.style.top = offset + 10 + 'px';

	})

});